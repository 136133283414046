import React, { useContext, useState, useLayoutEffect, useEffect, useRef } from "react";
import { splitInRows } from "./helperFunctions";
import { getPopularVideos, searchVideosByKeywords } from "./pexels";
import useCalculateGridCardSize from "../segments/helperHooks/useCalculateGridCardSize";
import { useSessionstorageState } from "rooks";
const BackgroundSelectionContext = React.createContext({});

export function useBackgroundSelectionContext() {
  return useContext(BackgroundSelectionContext);
}

export function BackgroundSelectionContextProvider({ children }) {
  const [assetsToChooseFrom, setAssetsToChooseFrom] = useState([]);
  const [assetsToChooseFromInRows, setAssetsToChooseFromInRows] = useState([]);
  //const [searchQuery, setSearchQuery] = useState();
  //Also stores currentlyActiveVisionMovieId when page gets refreshed
  const [searchQuery, setSearchQuery] = useSessionstorageState("mindmovie-app:searchQuery", "");
  const [newSearch, setNewSearch] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [nextPage, setNextPage] = useState();

  //This function splits the video array in the rows needed for the virtual list
  const gridCardSize = useCalculateGridCardSize();
  useEffect(() => {
    if (assetsToChooseFrom) {
      const numberOfRows = gridCardSize === 3 ? 4 : gridCardSize === 4 ? 3 : 2;
      const videosSplitInRows = splitInRows(assetsToChooseFrom, numberOfRows);
      setAssetsToChooseFromInRows(videosSplitInRows);
    }
  }, [assetsToChooseFrom, gridCardSize]);

  //When the backgroundSelection (index.js) page is initially loaded this useLayoutEffect loads the palceholder images
  useLayoutEffect(() => {
    if (searchQuery) {
      search(searchQuery);
    }
    if (searchQuery === "") {
      const fetchData = async () => {
        setLoadingAssets(true);
        try {
          const res = await getPopularVideos();
          setNextPage(res.next_page ? res.page + 1 : null);
          setAssetsToChooseFrom(res.videos);
        } catch (error) {
          console.log(error);
        }
        setLoadingAssets(false);
      };
      fetchData();
    }
  }, []);

  //When a new searchQuery is entered in the Searchbar this function fetches the first page of elements
  //and sets the new new searchQuery which triggers the virtual List to be scrolled to the top
  const search = async (searchQuery) => {
    setLoadingAssets(true);
    try {
      const res = await searchVideosByKeywords(searchQuery, 1);
        
      setNextPage(res.next_page ? res.page + 1 : null);
      setAssetsToChooseFrom(res.videos);
      setNewSearch(true);
    } catch (error) {
      console.log(error);
    }
    //Set loading to false
    setLoadingAssets(false);
    setSearchQuery(searchQuery);
  };

  //This function implements infinite scrolling and always fetches the next page of videos
  //no matter if a searchQuery exists or if the placeholder elements should be fetched
  const getNextPage = async () => {
    setLoadingAssets(true);
    //Loading next page
    try {
      if (!nextPage) return;
      const res = await (searchQuery
        ? searchVideosByKeywords(searchQuery, nextPage)
        : getPopularVideos(nextPage));
      setNextPage(res.next_page ? res.page + 1 : null);
      setAssetsToChooseFrom((prev) => prev.concat(res.videos));
    } catch (error) {
      console.log(error);
    }
    //Set loading to false
    setLoadingAssets(false);
  };

  const listRef = useRef();
  const scrollPositionInBackgroundSelectionList = useRef(0);
  function rememberBackgroundSelectionListScrollPosition() {
    scrollPositionInBackgroundSelectionList.current = listRef.current.state.scrollOffset;
  }

  const functions = {
    assetsToChooseFrom,
    assetsToChooseFromInRows,
    setAssetsToChooseFrom,
    loadingAssets,
    getNextPage,
    nextPage,
    search,
    searchQuery,
    setSearchQuery,
    newSearch,
    setNewSearch,
    listRef,
    scrollPositionInBackgroundSelectionList,
    rememberBackgroundSelectionListScrollPosition,
  };

  return (
    <BackgroundSelectionContext.Provider value={functions}>
      {children}
    </BackgroundSelectionContext.Provider>
  );
}
